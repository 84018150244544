.vengage__options {
  margin: 15px 0;
  margin-left: 30px;
}

.vengage__options__container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.vengage__option__item {
  padding: 8px 15px;
  border: 1px solid #2898ec;
  border-radius: 25px;
  color: #2898ec;
  font-size: 0.9rem;
  margin: 3px;
}
.vengage__calendar__block {
  padding: 4px 8px;
  /* border: 1px solid #7b7b7b; */
  border-radius: 4px;
  /* color: #7b7b7b; */
  color: black;
  font-size: 0.8rem;
  margin: 2px;
  margin-left: -29px;
  margin-right: 4px;
  flex: 0 0 15.333333%;
  text-align: center;
  cursor: pointer;
  background: #ffff;
  transition: background 0.3s;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 14px;
}

.vengage__option__item {
  padding: 4px 8px;
  border: 1px solid #7b7b7b;
  border-radius: 4px;
  /* color: #7b7b7b; */
  color: black;
  font-size: 0.8rem;
  margin: 2px 4px;
  flex: 0 0 20.333333%;
  text-align: center;
  cursor: pointer;
  background: #ffff;
  transition: background 0.3s;
  line-height:1.4em;
}
.vengage__calendar__block .vengage__option__item:hover,
.vengage__calendar__block .vengage__option__item:focus {
  background: #e7e7e7;
}
.vengage__calendar__block .vengage__option__item.active {
  background: #e7e7e7;
}

@media (max-width: 600px) {
  .vengage__calendar__block {
    margin-left: -25px !important;
  }
  .vengage__option__item {
    font-size: 10px;
  }
}
