.vengage__btn__primary {
  font-weight: 400 !important;
  color: #212529 ;
  border: none !important;
  text-align: center !important;
  vertical-align: middle !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #a9d18e ;
  border: 1px solid transparent !important;
  padding: 5px 5px !important;
  font-size: 0.8rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
  flex-grow: 1;
  margin-left: 4px !important;
  cursor: pointer;
  margin-right: 4px !important;
  opacity: 1;
  transition: opacity 0.3s;
}
.vengage__btn__primary:hover,
.vengage__btn__primary:focus,
.vengage__btn__primary:active {
  opacity: 0.8;
}

@media (max-width: 600px) {
  .vengage__btn__primary {
    padding: 5px 1px !important;

  }
  
}
