.doctor_list_N_searchbar{
  /* border: 1px solid black; */
  border-top: none;
  border-bottom: none;
  width: 95%;
  margin-left: 3px;
  height: 310px;

}
.vengage_doctor_list{
border: 1px solid lightgray;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
border-top: none;
box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}


.vengage__options {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: 30px;
  max-width: 82%;
}

.vengage__option {
  border-radius: 6px;
  padding: 0;
  border: 1px solid #7386a2;
  color: #1d1d1d;
  font-size: 0.8rem;
  margin: 4px 4px;
  background: transparent;
  display: inline-flex;
  cursor: pointer;
  overflow: hidden;
}
.option {
  /* border-radius: 6px; */
  width: 97.5%;
  padding: 2px;
  padding-left: 5px;
  border: 1px solid lightgray;
  border-left: none;
  border-right: none;
  border-top: none;
  color: #1d1d1d;
  font-size: 0.8rem;
  /* margin: 4px 4px; */
  margin-bottom: 2px;
  background: transparent;
  /* display: inline-flex; */
  cursor: pointer;
  /* overflow: hidden; */
}
.option:hover{
  background-color: lightgray;
  /* color: white; */
}
.vengage__option:hover,
.vengage__option:focus {
  background: #ececec;
}
.vengage__docImage {
  width: 24px;
  margin-right: 8px;
}
.vengage__txt {
  font-weight: 700;
  font-size: 14px;
  padding-top: 4px;
}
.vengage__subtxt {
  padding-bottom: 9px;
}
.vengage__citySelection__srNo {
  background: #119fda;
  border: 50%;
  width: 14px;
  height: 14px;
  font-size: 12px;
  color: white;
}

.scrollTobottom{
  /* border: 1px solid;
  border-radius: 50%; */
}
.scrollTobottom:hover {
  cursor: pointer;
  background-color: #F2F3F5;
}

.search_bar{
  width: 98%;
  margin-left: 3px;
}
.search_inputbar{
  width: 98%;
  /* border-radius: 5px;   */

}

.inputbar{
  width: 97.5%;
  padding: 7px;
  font-size: 15px;
  outline: none;
  border-radius: 5px;
  border: 1px solid lightgray;
  /* box-shadow: ; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  /* border-bottom: none; */
}
.inputbar:focus{
  /* border-color: blue; */
border: 1px solid #119FDA;
}

@media (max-width: 600px) {
  .vengage__option {
    font-size: 12px;
  }
  .vengage__docImage {
    width: 30px;
  }
  .option{
    width: 95.5%;
  }
}
